@import 'mixin';
@import 'global';

.bg-white {
  background-color: white;
}

.nav-bar {
  color: #fff !important;
  padding: 20px 40px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  background-color: transparent;

  &.bg-blue {
    background-color: rgba($color: #1271C8, $alpha: 0.95);
    transition: 1s;
  }

  .wrap-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
      font-size: 32px;
      font-weight: 600;
      font-style: italic;
      width: 145px;

      img {
        width: 100%;
      }
    }

    .nav {
      max-width: 670px;
      width: 100%;
      padding: 0 20px;
      justify-content: space-between;

      .nav-link {
        color: #ffffff;
        font-weight: 500;
        font-size: 1rem;
      }

      .show-popup {
        position: relative;

        &:hover .popup-container {
          display: initial;
        }

        .popup-container {
          display: none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 30px;
          width: 350px;
          padding-top: 30px;

          .popup-main {
            position: relative;
            background-color: #FAFBFC;
            border-radius: 10px;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              position: absolute;
              border-bottom: 10px solid white;
              top: -10px;
              left: 50%;
              transform: translateX(-50%);
            }


            .wrapper-popup {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              padding: 17px 15px;

              .talken {
                padding: 8px 15px;
                border-radius: 20px;

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .text-talken {
                  color: #666666;
                  padding-top: 10px;
                  font-size: 14px;
                }
              }

              .taal {
                padding: 8px 15px;
                border-radius: 20px;

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .text-taal {
                  color: #666666;
                  padding-top: 10px;
                  font-size: 14px;
                }
              }

              .nft-wallet {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .img-wrap {
                  border-radius: 10px;
                  background-color: #D6E0E6;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                .text-nft-wallet {
                  color: #666666;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 14px;
                    color: #8B9BB8;
                  }
                }
              }

              .nft {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .img-wrap {
                  border-radius: 10px;
                  background-color: #D6E0E6;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                .text-nft {
                  color: #666666;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 14px;
                    color: #8B9BB8;
                  }
                }
              }

              .defi {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                .img-wrap {
                  border-radius: 10px;
                  background-color: #D6E0E6;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .text-defi {
                  color: #666666;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 14px;
                    color: #8B9BB8;
                  }
                }
              }

              .about-us {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                .img-wrap {
                  border-radius: 10px;
                  background-color: #D6E0E6;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .text-about-us {
                  color: #666666;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 14px;
                    color: #8B9BB8;
                  }
                }
              }

              .contact-us {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                .img-wrap {
                  border-radius: 10px;
                  background-color: #D6E0E6;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                &:hover {
                  background-color: #D6E0E6;
                  transition: 500ms;
                }

                .text-contact-us {
                  color: #666666;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 14px;
                    color: #8B9BB8;
                  }
                }
              }


            }

          }

          .popup-otherpage {
            position: relative;
            background-color: #113577;
            border-radius: 10px;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              position: absolute;
              border-bottom: 10px solid #113577;
              top: -10px;
              left: 50%;
              transform: translateX(-50%);
            }

            .wrapper-popup {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              padding: 17px 15px;

              .talken {
                padding: 8px 15px;
                border-radius: 20px;

                &:hover {
                  background-color: #052662;
                  transition: 500ms;
                }

                .text-talken {
                  color: #DDDDDD;
                  padding-top: 10px;
                  font-size: 14px;
                }
              }

              .taal {
                padding: 8px 15px;
                border-radius: 20px;

                &:hover {
                  background-color: #052662;
                  transition: 500ms;
                }

                .text-taal {
                  color: #DDDDDD;
                  padding-top: 10px;
                  font-size: 14px;
                }
              }

              .nft-wallet {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                &:hover {
                  background-color: #052662;
                  transition: 500ms;
                }

                .img-wrap {
                  border-radius: 10px;
                  background-color: black;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                .text-nft-wallet {
                  color: #DDDDDD;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 12px;
                    color: #8B9BB8;
                  }
                }
              }

              .nft {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                &:hover {
                  background-color: #052662;
                  transition: 500ms;
                }

                .img-wrap {
                  border-radius: 10px;
                  background-color: black;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                .text-nft {
                  color: #DDDDDD;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 12px;
                    color: #8B9BB8;
                  }
                }
              }

              .defi {
                padding: 8px 15px;
                border-radius: 20px;
                display: flex;

                &:hover {
                  background-color: #052662;
                  transition: 500ms;
                }

                .img-wrap {
                  border-radius: 10px;
                  background-color: black;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }

                .img-wrap>img {
                  width: 30px;
                }

                .text-defi {
                  color: #DDDDDD;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 14px;

                  .sub {
                    font-size: 12px;
                    color: #8B9BB8;
                  }
                }
              }
            }

          }
        }




      }
    }

    .text-end {
      font-size: 24px;
      font-weight: 600;

      span {
        color: #fff;
      }
    }
  }

  @include Mobile() {
    padding: 10px 20px;

    .wrap-menu {
      .header-logo {
        width: 125px;
      }

      .text-end {
        font-size: 17px;

        img {
          height: 15px;
        }
      }
    }
  }

  @include Tablet() {
    .wrap-menu {
      .nav {}
    }
  }
}

.navbar-mb {
  width: 350px;
  height: 100vh;
  background-color: #113577;
  position: fixed;
  top: 0;
  z-index: 99999;
  color: #fff;
  left: 100%;
  padding: 20px 20px 0;
  transition: all 500ms;
  // transform: translateX(100%);

  &.show {
    // left: 0;
    transform: translateX(-100%);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
      width: 125px;

      img {
        width: 100%;
      }
    }

    .text-end {
      span {
        font-weight: 600;
      }

      img {
        width: 40px;
      }
    }
  }

  .wrap-menu {
    text-align: center;
    padding-top: 150px;
    font-size: 30px;

    .custom-nav {
      padding: 0;

      .accordion-item {
        border: none;
        background-color: transparent;
      }

      .nav-partners {
        .accordion-button::after {
          display: none;
        }
      }

      .accordion-header {

        .accordion-button {
          background-color: transparent;
          border-bottom: 1px solid #ffffff99;
          padding: 1rem 0;

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed)::after {
            background-image: url("../assets/img/icon_arrow_up.png");
          }

          &::after {
            background-image: url("../assets/img/icon_arrow_down.png");
          }

          .nav-link {
            color: #ffffff;
            font-weight: 500;
            font-size: 1rem;
            padding-left: 0;
          }
        }
      }

      .accordion-collapse {
        text-align: initial;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        .accordion-body {
          a {
            display: inline-block;
            padding-bottom: 16px;
            opacity: 0.5;
            font-size: 14px;
            transition: 500ms;
            text-decoration: none;
            color: #fff;

            &:hover {
              opacity: 0.8;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

          .active-nav {
            opacity: 1;
          }


        }

      }

    }
  }

  @include Mobile() {
    width: 100vw;
  }
}