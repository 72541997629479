@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'scss';
@import '~antd/dist/antd.css';
@import 'homepage.scss';
@import 'navbar.scss';
@import 'footer.scss';
@import 'global.scss';
@import 'pricing.scss';
@import 'customers.scss';
@import 'product.scss';
