@import 'variables';
@import 'mixin';
@import 'global';

.product-page {
  .wrapper {
    // section 01

    .section-01 {

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $page-content-width;
        margin: auto;
        padding: 140px 0 100px 0;

        .content {
          flex: 1 1 50%;

          .text-header {
            font-size: 48px;
            line-height: 72px;
            color: #113577;
          }

          .text-middle {
            font-size: 18px;
            line-height: 32px;
            padding: 35px 0 50px 0;
          }
        }

        .img-product-pc02 {
          flex: 1 1 50%;

          img {
            width: 100%;
          }
        }
      }

    }

    .section-nft-wallet-01 {

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $page-content-width;
        margin: auto;
        padding: 250px 0 150px 0;

        .content {
          flex: 1 1 40%;

          .text-header {
            font-size: 48px;
            line-height: 72px;
            color: #113577;
          }

          .text-middle {
            font-size: 18px;
            line-height: 32px;
            padding: 35px 0 50px 0;
          }
        }

        .img-product-pc02 {
          flex: 1 1 60%;

          img {
            width: 100%;
          }
        }
      }

    }

    .section-nft-01 {

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $page-content-width;
        margin: auto;
        padding: 250px 0 150px 0;

        .content {
          flex: 1 1 40%;

          .text-header {
            font-size: 48px;
            line-height: 72px;
            color: #113577;
          }

          .text-middle {
            font-size: 18px;
            line-height: 32px;
            padding: 35px 0 50px 0;
          }
        }

        .img-product-pc02 {
          flex: 1 1 60%;

          img {
            width: 100%;
          }
        }
      }

    }

    // section 02

    .section-02 {
      background: rgb(40, 98, 227);
      background: linear-gradient(90deg, rgba(40, 98, 227, 1) 0%, rgba(163, 121, 252, 1) 100%);
      color: #ffffff;
      align-items: center;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding-top: 150px;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 90px 0;
            white-space: break-spaces;
          }
        }

        .img-product-pc04 {
          position: relative;
          margin: auto;
          display: grid;
          color: #DEDEDE;
          font-size: 18px;
          line-height: 26px;
          grid-template-columns: 200px 1fr 200px;

          img {
            width: 100%;
          }



          .text-1 {
            position: absolute;
            bottom: 140px;
            right: 0;
            width: fit-content;
            height: fit-content;

          }

          .text-2 {
            position: absolute;
            bottom: 10%;
            right: 9%;
            width: fit-content;
            height: fit-content;

          }

          .text-3 {
            position: absolute;
            left: 0;
            bottom: 38%;
            width: fit-content;
            height: fit-content;

          }


        }
      }

    }

    .section-nft-wallet-02 {
      background: rgb(40, 98, 227);
      background: linear-gradient(90deg, rgba(40, 98, 227, 1) 0%, rgba(163, 121, 252, 1) 100%);
      color: #ffffff;
      align-items: center;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding-top: 150px;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 90px 0;
            white-space: break-spaces;
          }
        }

        .img-product-pc04 {
          position: relative;
          margin: auto;
          // display: grid;
          // width: 55%;
          width: 660px;
          color: #DEDEDE;
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 100px;
          // grid-template-columns: 200px 1fr 200px;

          img {
            width: 100%;
            margin-bottom: 100px;
          }



          .text-1 {
            position: absolute;
            bottom: 140px;
            right: 0;
            width: fit-content;
            height: fit-content;

          }

          .text-2 {
            position: absolute;
            bottom: 10%;
            right: 9%;
            width: fit-content;
            height: fit-content;

          }

          .text-3 {
            position: absolute;
            left: 10%;
            bottom: 38%;
            width: fit-content;
            height: fit-content;

          }


        }
      }

    }

    .section-nft-02 {
      background: rgb(40, 98, 227);
      background: linear-gradient(90deg, rgba(40, 98, 227, 1) 0%, rgba(163, 121, 252, 1) 100%);
      color: #ffffff;
      align-items: center;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0 120px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 90px 0;
            white-space: break-spaces;
          }
        }

        .img-product-pc04 {
          width: 980px;

          img {
            width: 100%;
            margin-bottom: 100px;
          }


        }
      }

    }

    // section 03

    .section-03 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 100px 0;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;

          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 40px 0;
          }
        }

        .content-bottom {
          display: flex;
          justify-content: space-between;
          text-align: center;
          width: $page-content-width;
          margin: auto;
          gap: 25px;

          .content-left {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 100%;

            .img-product-pc05 {
              padding: 35px 0 25px 0;
            }

            .text-header {
              font-size: 28px;
              color: #111111;
            }

            .text-bottom {
              padding: 20px 0 70px 0;
              color: #666666;

            }
          }

          .content-right {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 100%;

            .images {
              padding: 35px 0 60px 0;
            }

            .text-header {
              font-size: 28px;
              line-height: 72px;
              color: #111111;
            }

            .text-bottom {
              white-space: break-spaces;
              color: #666666;
            }
          }
        }
      }
    }

    .section-nft-wallet-03 {
      background-color: #ffffff; //#fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;

          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 40px 0;
          }
        }

        .content-bottom {
          display: flex;
          justify-content: center;
          width: $page-content-width;
          margin: auto;

          .content-img {
            width: 65%;
          }

          .content-left {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 100%;

            .img-product-pc05 {
              padding: 35px 0 25px 0;
            }

            .text-header {
              font-size: 28px;
              color: #111111;
            }

            .text-bottom {
              padding: 20px 0 70px 0;
              color: #666666;

            }
          }

          .content-right {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 100%;

            .images {
              padding: 35px 0 60px 0;
            }

            .text-header {
              font-size: 28px;
              line-height: 72px;
              color: #111111;
            }

            .text-bottom {
              white-space: break-spaces;
              color: #666666;
            }
          }
        }
      }
    }

    .section-nft-03 {
      background-color: #ffffff;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0 150px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            white-space: break-spaces;
            padding: 22px 0 70px 0;
          }
        }

        .img-product-pc10 {
          width: 980px;

          img {
            width: 100%;
          }
        }
      }

    }

    // section 04

    .section-04 {
      background: rgb(40, 98, 227);
      background: linear-gradient(90deg, rgba(40, 98, 227, 1) 0%, rgba(163, 121, 252, 1) 100%);

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0 140px 0;

        .wrapper-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content {
            flex: 0 0 40%;

            .text-header {
              font-size: 42px;
              line-height: 60px;
              white-space: break-spaces;
              font-weight: 600;
              padding-bottom: 22px;
            }

            .text-bottom {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
            }
          }

          .img-product-pc08 {
            flex: 0 0 60%;

            img {
              width: 100%;
            }
          }
        }

        .wrapper-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 140px;
          gap: 100px;

          .img-product-pc09 {
            flex: 1 1 60%;

            img {
              width: 100%;
            }
          }

          .content {
            flex: 1 1 40%;

            .text-header {
              font-size: 42px;
              line-height: 60px;
              white-space: break-spaces;
              font-weight: 600;
              padding-bottom: 22px;
            }

            .text-bottom {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
              color: #666666;
              padding-bottom: 60px;
            }
          }
        }
      }

    }

    .section-nft-wallet-04 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0 140px 0;

        .wrapper-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content {
            flex: 0 0 40%;

            .text-header {
              font-size: 42px;
              line-height: 60px;
              white-space: break-spaces;
              font-weight: 600;
              padding-bottom: 22px;
            }

            .text-bottom {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
            }
          }

          .img-product-pc08 {
            flex: 0 0 60%;

            img {
              width: 100%;
            }
          }
        }

        .wrapper-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 140px;
          gap: 100px;

          .img-product-pc09 {
            flex: 1 1 60%;

            img {
              width: 100%;
            }
          }

          .content {
            flex: 1 1 40%;

            .text-header {
              font-size: 42px;
              line-height: 60px;
              white-space: break-spaces;
              font-weight: 600;
              padding-bottom: 22px;
            }

            .text-bottom {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
            }
          }
        }
      }

    }

    .section-nft-04 {
      background: #fafbfc;
      color: black;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 135px 0 185px 0;

        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 22px 0 100px 0;
          }
        }

        .wrapper-bottom {
          display: flex;
          justify-content: space-between;
          text-align: center;

          .img-product-pc19 {
            flex: 1 1 50%;

            img {
              width: 50%;
            }
          }

          .img-product-pc20 {
            flex: 1 1 50%;

            img {
              width: 50%;
            }
          }
        }
      }
    }

    // section 05
    .section-05 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 95px 0 115px 0;

        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            white-space: break-spaces;
            padding: 22px 0 70px 0;
          }
        }

        .img-product-pc10 {
          text-align: center;

          img {
            width: 100%;
          }
        }
      }

    }

    .section-nft-wallet-05 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0;

        .content-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;

          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 20px 0 40px 0;
          }
        }

        .content-bottom {
          display: flex;
          justify-content: space-between;
          text-align: center;
          align-items: center;
          width: $page-content-width;
          margin: auto;
          gap: 0px;

          .content-left {
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 50%;
            width: 300px;

            .img-product-pc05 {
              padding: 35px 0 25px 0;
              width: 50%;
            }

            .text-header {
              font-size: 28px;
              color: #111111;
            }

            .text-bottom {
              padding: 20px 0 70px 0;
              color: #666666;

            }
          }

          .content-right {
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            flex: 1 1 50%;
            width: 550px;

            img {
              width: 100%;
            }

            .text-header {
              font-size: 28px;
              line-height: 72px;
              color: #111111;
            }

            .text-bottom {
              white-space: break-spaces;
              color: #666666;
            }
          }
        }
      }
    }

    // section 06

    .section-06 {
      background-color: #F2F6F9;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 115px 0 140px 0;
        align-items: center;

        .wrapper-left {
          font-size: 40px;
          line-height: 56px;
          white-space: break-spaces;
          color: #113577;
        }

        .wrapper-right {
          text-align: center;

          .line {
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 40px;
          }

          .wrapper-bottom {
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
            width: 366px;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .section-nft-wallet-06 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 150px 0 150px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            white-space: break-spaces;
            padding: 22px 0 70px 0;
          }
        }

        .img-product-pc10 {
          width: 980px;

          img {
            width: 100%;
          }
        }
      }

    }

    // section 07 
    .section-07 {
      .wrapper {
        width: $page-content-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 250px 0 150px 0;
        align-items: center;

        .wrapper-left {
          flex: 1 1 50%;

          .text-header {
            font-size: 48px;
            line-height: 72px;
            white-space: break-spaces;
          }

          .text-bottom {
            font-size: 18px;
            line-height: 32px;
            white-space: break-spaces;
            padding: 35px 0 50px 0;
          }
        }

        .wrapper-right {
          flex: 1 1 50%;

          img {
            width: 100%;
            // margin-left: 130px;
          }
        }
      }
    }

    // section 08

    .section-08 {
      background-color: #00A755;
      color: #ffffff;
      text-align: center;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        align-items: center;
        padding: 155px 0 200px 0;

        .wrapper-header {

          .text-header {
            font-size: 42px;
            line-height: 72px;
            padding-bottom: 22px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            white-space: break-spaces;
            padding-bottom: 115px;
          }
        }

        .wrapper-bottom {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 50px;

          .img-product-pc16 {
            img {
              width: 100%;
            }
          }

          .img-product-pc17 {
            img {
              width: 100%;
            }
          }

          .img-product-pc18 {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    // section 09

    .section-09 {
      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 135px 0 185px 0;

        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
            font-weight: 600;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding: 22px 0 100px 0;
          }
        }

        .wrapper-bottom {
          display: flex;
          justify-content: space-between;

          .wrapper-left {
            flex: 1 1 50%;

            .staking {
              font-size: 28px;
              line-height: 72px;
              padding-left: 25px;
            }

            .img-product-pc19 {
              img {
                width: 100%;
              }
            }
          }

          .wrapper-right {
            flex: 1 1 50%;

            .yield-farming {
              font-size: 28px;
              line-height: 72px;
              padding-left: 25px;
            }

            .img-product-pc20 {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    // section 10
    .section-10 {
      background-color: #fafbfc;

      .wrapper {
        width: $page-content-width;
        margin: auto;
        padding: 140px 0;

        .wrapper-header {
          text-align: center;

          .text-header {
            font-size: 42px;
            line-height: 72px;
          }

          .text-bottom {
            font-size: 20px;
            line-height: 32px;
            padding-top: 22px;
          }
        }

        .wrapper-middle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 50px;
          padding: 100px 0 90px 0;

          .img-product-pc21 {
            flex: 1 1 50%;

            img {
              width: 100%;
            }
          }

          .text-right {
            font-size: 28px;
            line-height: 44px;
            white-space: break-spaces;
            flex: 1 1 50%;
          }
        }

        .wrapper-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 85px;

          .img-product-pc22 {
            flex: 1 1 50%;

            img {
              width: 100%;
            }
          }

          .text-left {
            font-size: 28px;
            line-height: 44px;
            white-space: break-spaces;
            flex: 1 1 50%;
            text-align: end;
          }
        }
      }
    }

    // section-11

    .section-11 {
      .wrapper {
        width: $page-content-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 140px 0;
        align-items: center;

        .wrapper-left {
          font-size: 40px;
          line-height: 56px;
          white-space: break-spaces;
          color: #113577;
        }

        .wrapper-right {
          text-align: center;

          .line {
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 40px;
          }

          .wrapper-bottom {
            padding-top: 40px;
            display: flex;
            flex-direction: column;

            .launch-app {
              border-radius: 50px;
              background-color: black;
              padding: 20px;
              color: white;
              font-size: 22px;
              font-weight: 600;

              &:hover {
                background-color: #5D5858;
                // color: black;
              }
            }
          }
        }
      }
    }
  }


  @include Mobile() {
    .wrapper {
      .section-01 {
        .wrapper {
          flex-direction: column;
          padding: 95px 20px 80px;

          .img-product-pc02 {
            height: 290px;
            padding-bottom: 45px;
          }

          .content {
            text-align: center;
            white-space: break-spaces;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-middle {
              padding: 23px 0 50px 0;
              font-size: 16px;
              line-height: 24px;
            }

          }
        }

      }

      .section-nft-wallet-01 {
        .wrapper {
          flex-direction: column;
          padding: 140px 20px 100px 20px;

          .img-product-pc02 {
            height: 242px;
          }

          .content {
            text-align: center;
            white-space: break-spaces;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-middle {
              padding: 23px 0 50px 0;
              font-size: 16px;
              line-height: 24px;
            }

          }
        }
      }

      .section-nft-01 {
        .wrapper {
          flex-direction: column;
          padding: 95px 20px 80px;

          .img-product-pc02 {
            height: 290px;
            padding-bottom: 45px;
          }

          .content {
            text-align: center;
            white-space: break-spaces;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-middle {
              padding: 23px 0 50px 0;
              font-size: 16px;
              line-height: 24px;
            }

          }
        }

      }

      .section-02 {
        background: rgb(163, 121, 252);
        background: linear-gradient(0deg, rgba(163, 121, 252, 1) 0%, rgba(40, 98, 227, 1) 100%);

        .wrapper {
          padding: 65px 20px 90px;

          .img-product-pc04 {
            display: block;

            .text-1 {
              top: 50%;
              left: 15%;
            }

            .text-2 {
              bottom: -60px;
              left: 50%;
            }

            .text-3 {
              left: 0;
              width: 105px;
              bottom: 6%;

            }
          }

          .content-header {
            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              padding: 24px 0 0 0;
              white-space: break-spaces;
            }
          }

          .img-product-pc04 {
            .wrapper-text {
              .text-1 {
                top: 50%;
                left: 15%;
              }

              .text-2 {
                top: calc(100% + 10px);
                right: 20px;
              }

              .text-3 {
                left: 15%;
                bottom: 8%;
              }
            }
          }
        }
      }

      .section-nft-wallet-02 {
        background: rgb(163, 121, 252);
        background: linear-gradient(0deg, rgba(163, 121, 252, 1) 0%, rgba(40, 98, 227, 1) 100%);

        .wrapper {
          padding: 65px 20px 80px;

          .img-product-pc04 {
            width: 100%;

            .img {
              width: 100%;
              margin-bottom: 0;
            }
          }

          .content-header {
            padding-bottom: 50px;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              padding: 24px 0 0 0;
              white-space: break-spaces;
            }
          }

          .img-product-pc04 {
            .wrapper-text {
              .text-1 {
                top: 50%;
                left: 15%;
              }

              .text-2 {
                top: calc(100% + 10px);
                right: 20px;
              }

              .text-3 {
                left: 15%;
                bottom: 8%;
              }
            }
          }
        }
      }

      .section-nft-02 {
        background: rgb(163, 121, 252);
        background: linear-gradient(0deg, rgba(163, 121, 252, 1) 0%, rgba(40, 98, 227, 1) 100%);

        .wrapper {
          padding: 65px 20px 120px;

          .img-product-pc04 {
            width: 100%;

            .img {
              width: 100%;
              margin-bottom: 0;
            }
          }

          .content-header {
            padding-bottom: 50px;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              padding: 24px 0 0 0;
              white-space: break-spaces;
            }
          }

          .img-product-pc04 {
            .wrapper-text {
              .text-1 {
                top: 50%;
                left: 15%;
              }

              .text-2 {
                top: calc(100% + 10px);
                right: 20px;
              }

              .text-3 {
                left: 15%;
                bottom: 8%;
              }
            }
          }
        }
      }

      .section-03 {
        .wrapper {
          padding: 65px 0 90px;


          .content-header {
            padding: 0 20px;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
            }
          }

          .content-bottom {
            flex-direction: column;
            padding: 0 20px;

            .content-left {
              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                white-space: break-spaces;
                padding: 20px 0 50px 0;
              }
            }

            .content-right {
              padding: 10px 0;

              .images {
                padding: 0;
              }

              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                padding: 20px 0 50px 0;
                white-space: break-spaces;
              }
            }
          }
        }
      }

      .section-nft-wallet-03 {
        .wrapper {
          padding: 65px 0 90px;


          .content-header {
            padding: 0 20px;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
            }
          }

          .content-bottom {
            flex-direction: column;
            padding: 0 20px;

            .content-img {
              width: 100%;
            }

            .content-left {
              padding: 0 20px;

              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                white-space: break-spaces;
                padding: 20px 0 50px 0;
              }
            }

            .content-right {
              padding: 0 20px;

              .images {
                padding: 0;
              }

              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                padding: 20px 0 50px 0;
                white-space: break-spaces;
              }
            }
          }
        }
      }

      .section-nft-03 {
        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 23px 0 51px 0;
            }
          }

          .img-product-pc10 {
            width: 100%;

            img {
              // max-width: 40vw;
              width: 100%;
            }
          }
        }
      }

      .section-04 {
        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            flex-direction: column;

            .content {
              text-align: center;

              .text-header {
                font-size: 28px;
                line-height: 42px;
              }

              .text-bottom {
                font-size: 16px;
                line-height: 24px;
              }
            }

            .img-product-pc08 {
              .text-img {
                font-size: 16px;
                line-height: 26px;
                white-space: break-spaces;
                text-align: center;
              }
            }
          }

          .wrapper-bottom {
            flex-direction: column;
            padding: 80px 0 0 0;
            gap: 50px;

            .content {
              text-align: center;

              .text-header {
                font-size: 28px;
                line-height: 42px;
                padding-bottom: 24px;
              }

              .text-bottom {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }

      .section-nft-wallet-04 {
        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            flex-direction: column;

            .content {
              text-align: center;

              .text-header {
                font-size: 28px;
                line-height: 42px;
              }

              .text-bottom {
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 40px;
              }
            }

            .img-product-pc08 {
              .text-img {
                font-size: 16px;
                line-height: 26px;
                white-space: break-spaces;
                text-align: center;
              }
            }
          }

          .wrapper-bottom {
            flex-direction: column;
            padding: 80px 0 0 0;
            gap: 50px;

            .content {
              text-align: center;

              .text-header {
                font-size: 28px;
                line-height: 42px;
                padding-bottom: 24px;
              }

              .text-bottom {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }

      .section-nft-04 {
        background: #fafbfc;
        color: black;

        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            text-align: center;

            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 24px 0 50px 0;
            }
          }

          .wrapper-bottom {
            // flex-direction: column;
            gap: 30px;

            .img-product-pc19 {

              img {
                width: 100%;
              }
            }

            .img-product-pc20 {
              img {
                width: 100%;
              }
            }
          }
        }
      }

      .section-05 {

        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 23px 0 51px 0;
            }
          }
        }
      }

      .section-nft-wallet-05 {
        .wrapper {
          padding: 65px 0 90px;


          .content-header {
            padding: 0 20px;

            .text-header {
              font-size: 28px;
              line-height: 42px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
            }
          }

          .content-bottom {
            flex-direction: column;
            padding: 0 20px;

            .content-left {
              width: 50%;

              .img-product-pc05 {
                width: 100%;
              }

              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                white-space: break-spaces;
                padding: 20px 0 50px 0;
              }
            }

            .content-right {
              padding-top: 50px;
              width: 80%;

              img {
                width: 100%;
              }

              .text-header {
                font-size: 20px;
                line-height: 42px;
              }

              .text-bottom {
                padding: 20px 0 50px 0;
                white-space: break-spaces;
              }
            }
          }
        }
      }

      .section-06 {
        .wrapper {
          padding: 65px 20px 90px;
          flex-direction: column;

          .wrapper-left {
            font-size: 30px;
            line-height: 46px;
            padding-bottom: 60px;
            text-align: center;
          }

          .wrapper-right {
            .img-product-pc10 {
              img {
                max-width: 40vw;
                padding: 0 20px;
              }
            }

            .line {
              margin: 0 20px;
            }

            .wrapper-bottom {
              width: 320px;
              padding: 40px 20px 0;

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .section-nft-wallet-06 {
        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 23px 0 51px 0;
            }
          }

          .img-product-pc10 {
            width: 100%;

            img {
              // max-width: 40vw;
              width: 100%;
            }
          }
        }
      }

      .section-07 {
        .wrapper {
          padding: 100px 20px;
          flex-direction: column;

          .wrapper-left {
            text-align: center;

            .text-header {
              font-size: 28px;
              line-height: 42px;
              padding-top: 85px;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 23px 0 51px 0;
            }
          }
        }
      }

      .section-08 {
        .wrapper {
          padding: 70px 20px 90px;

          .wrapper-header {
            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 24px 0 50px 0;
            }
          }

          .wrapper-bottom {
            grid-template-columns: none;
            grid-template-rows: repeat(3, 1fr);
          }
        }
      }

      .section-09 {
        .wrapper {
          padding: 65px 20px 90px;

          .wrapper-header {
            text-align: center;

            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 24px 0 50px 0;
            }
          }

          .wrapper-bottom {
            flex-direction: column;

            .wrapper-left {
              .staking {
                font-size: 20px;
              }
            }

            .wrapper-right {
              .yield-farming {
                font-size: 20px;
                padding-top: 60px;
              }
            }
          }
        }
      }

      .section-10 {
        .wrapper {
          padding: 60px 20px 90px;
          text-align: center;

          .wrapper-header {

            .text-header {
              font-size: 28px;
              line-height: 42px;
              white-space: break-spaces;
            }

            .text-bottom {
              font-size: 16px;
              line-height: 24px;
              white-space: break-spaces;
              padding: 26px 0 44px 0;
            }
          }

          .wrapper-middle {
            flex-direction: column;
            padding: 0 0 60px 0;
            gap: 0;

            .text-right {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
            }
          }

          .wrapper-bottom {
            flex-direction: column;
            gap: 28px;

            .text-left {
              font-size: 20px;
              line-height: 32px;
              white-space: break-spaces;
              text-align: center;
            }
          }
        }
      }

      .section-11 {
        .wrapper {
          padding: 65px 20px 90px;
          flex-direction: column;

          .wrapper-left {
            font-size: 30px;
            line-height: 46px;
            padding-bottom: 60px;
            text-align: center;
          }

          .wrapper-right {
            .img-product-pc23 {
              img {
                max-width: 60vw;
              }
            }
          }
        }
      }
    }
  }

  @include Tablet() {
    .wrapper {
      .section-01 {
        .wrapper {
          padding: 140px 40px 100px;

          .content {
            .text-header {
              font-size: 30px;
              line-height: 48px;

            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-nft-wallet-01 {
        .wrapper {
          padding: 140px 40px 100px;

          .content {
            .text-header {
              font-size: 30px;
              line-height: 48px;

            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-nft-01 {
        .wrapper {
          padding: 140px 40px 100px;

          .content {
            .text-header {
              font-size: 30px;
              line-height: 48px;

            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-02 {

        .wrapper {
          padding: 95px 40px 0 40px;

          .img-product-pc04 {
            grid-template-columns: 120px 1fr 120px;

            .text-1 {
              bottom: 100px;
            }
          }

          .conten-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-nft-wallet-02 {
        .wrapper {
          padding: 95px 40px 0 40px;

          .img-product-pc04 {
            grid-template-columns: 120px 1fr 120px;

            .text-1 {
              bottom: 40px;
            }

            .text-2 {
              bottom: 0;
              right: -6%;
            }

            .text-3 {
              bottom: 22%;
            }
          }

          .conten-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-nft-02 {
        .wrapper {
          padding: 95px 40px 0 40px;

          .img-product-pc04 {
            width: 650px;

            img {
              width: 100%;
            }
          }

          .conten-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-03 {
        .wrapper {
          .content-header {
            padding: 0 40px;

            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .content-bottom {
            padding: 0 40px;

            .content-left {
              padding: 0 10px;

            }

            .content-right {
              .images {
                padding: 35px 0 0 0;
              }

              .text-header {
                line-height: 42px;
              }

              .text-bottom {
                padding: 10px 0;
              }
            }
          }
        }
      }

      .section-nft-wallet-03 {
        .wrapper {
          .content-header {
            padding: 0 40px;

            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .content-bottom {
            padding: 0 40px;

            .content-img {
              width: 100%;
            }

            .content-left {
              padding: 0 20px;

              .text-header {
                font-size: 22px;
              }
            }

            .content-right {
              padding: 0 20px;

              .images {
                padding: 35px 0 0 0;
              }

              .text-header {
                font-size: 22px;
                line-height: 42px;
              }

              .text-bottom {
                padding: 10px 0;
              }
            }
          }
        }
      }

      .section-nft-03 {
        .wrapper {
          padding: 95px 40px 115px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .img-product-pc10 {
            width: auto;
          }
        }
      }

      .section-04 {
        .wrapper {
          padding: 120px 40px 140px 40px;

          .wrapper-header {
            .content {
              .text-header {
                font-size: 30px;
                line-height: 48px;
              }

              .text-bottom {
                font-size: 16px;
              }
            }
          }

          .wrapper-bottom {
            .content {
              .text-header {
                font-size: 30px;
                line-height: 48px;
              }

              .text-bottom {
                font-size: 16px;
              }
            }
          }
        }
      }

      .section-nft-wallet-04 {
        .wrapper {
          padding: 120px 40px 140px 40px;

          .wrapper-header {
            .content {
              .text-header {
                font-size: 30px;
                line-height: 48px;
              }

              .text-bottom {
                font-size: 16px;
              }
            }
          }

          .wrapper-bottom {
            .content {
              .text-header {
                font-size: 30px;
                line-height: 48px;
              }

              .text-bottom {
                font-size: 16px;
              }
            }
          }
        }
      }

      .section-nft-04 {
        .wrapper {
          padding: 135px 40px 150px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-05 {
        .wrapper {
          padding: 95px 40px 115px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-nft-wallet-05 {
        .wrapper {
          .content-header {
            padding: 0 40px;

            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .content-bottom {
            padding: 0 40px;
            align-items: center;

            .content-left {

              .text-header {
                font-size: 22px;
              }
            }

            .content-right {

              .images {
                padding: 35px 0 0 0;

                img {
                  width: 100%;
                }
              }

              .text-header {
                font-size: 22px;
                line-height: 42px;
              }

              .text-bottom {
                padding: 10px 0;
              }
            }
          }
        }
      }

      .section-06 {
        .wrapper {
          padding: 115px 40px 140px 40px;

          .wrapper-left {
            font-size: 30px;
            line-height: 48px;
          }
        }
      }

      .section-nft-wallet-06 {
        .wrapper {
          padding: 95px 40px 115px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .img-product-pc10 {
            width: auto;
          }
        }
      }

      .section-07 {
        .wrapper {
          padding: 200px 40px 150px 40px;

          .wrapper-left {
            flex: 1 1 50%;

            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .wrapper-right {
            flex: 1 1 50%;
          }
        }
      }

      .section-08 {
        .wrapper {
          padding: 100px 40px 150px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-09 {
        .wrapper {
          padding: 135px 40px 150px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }
        }
      }

      .section-10 {
        .wrapper {
          padding: 140px 40px;

          .wrapper-header {
            .text-header {
              font-size: 30px;
              line-height: 48px;
            }

            .text-bottom {
              font-size: 16px;
            }
          }

          .wrapper-middle {
            .text-right {
              font-size: 22px;
            }
          }

          .wrapper-bottom {
            .text-left {
              font-size: 22px;
            }
          }
        }
      }

      .section-11 {
        .wrapper {
          padding: 115px 40px 140px 40px;

          .wrapper-left {
            font-size: 30px;
            line-height: 48px;
          }
        }
      }
    }
  }
}