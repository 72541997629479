@import 'variables';
@import 'mixin';
@import 'global';

.home-page {
  .home-header {
    max-height: 970px;
    padding-top: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/img/main01.png');
    background-position: center;
    overflow: hidden;

    .wrapper-content {
      text-align: center;
      color: #ffffff;

      .text-header {
        font-size: 74px;
        font-weight: 700;
        white-space: break-spaces;
        line-height: 84px;
      }

      .text-bottom {
        font-size: 18px;
        white-space: break-spaces;
        line-height: 32px;
        opacity: 80%;
        text-align: center;
        width: 600px;
        margin: 20px auto;
      }

      .main-pc-img {
        max-width: 100vw;
        height: 570px;
        align-items: center;
      }
    }
  }

  .home-content {
    padding: 20px 20px 50px 20px;
    width: $page-content-width;
    margin: auto;

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 75px 0 75px 0;

      .main-pc03 {
        height: 510px;
        flex: 0 0 50%;

        img {
          width: 100%;
        }
      }

      .wrapper-right {
        flex: 0 1 50%;

        .text-header {
          font-size: 42px;
          font-weight: 700;
          color: #444444;
          white-space: break-spaces;
          line-height: 54px;
        }

        .text-bottom {
          padding: 30px 0 50px 0;
          font-size: 18px;
          color: #444444;
          white-space: break-spaces;
          line-height: 32px;
        }

        .btn-explore {
          border-radius: 50px;
          width: 200px;
          height: 60px;
          color: #163671;
          background-color: #ffffff;
          border: 2px solid #163671;
          box-shadow: 5px 10px 18px #a8b9d1;

          img {
            padding-left: 10px;
          }
        }
      }
    }

    .content-second {
      padding: 75px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 65px;

      .main-pc06 {
        // height: 450px;
        width: 444px;
        flex: 1 1 40%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          box-shadow: 5px 10px 18px #a8b9d1;
        }
      }

      .wrapper-right {
        flex: 0 1 60%;

        .text-header {
          font-size: 42px;
          font-weight: 700;
          color: #5c518b;
          white-space: break-spaces;
          line-height: 54px;
        }

        .text-bottom {
          padding: 30px 0 50px 0;
          font-size: 18px;
          color: #444444;
          white-space: break-spaces;
          line-height: 32px;
        }

        .btn-explore {
          border-radius: 50px;
          width: 200px;
          height: 60px;
          color: #163671;
          background-color: #ffffff;
          border: 2px solid #163671;
          box-shadow: 5px 10px 18px #a8b9d1;

          img {
            padding-left: 10px;
          }
        }
      }
    }

    .content-bottom {
      padding: 75px 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .main-pc04 {
        height: 610px;
        flex: 1 1 40%;

        img {
          width: 100%;
        }
      }

      .wrapper-right {
        flex: 0 1 60%;

        .text-header {
          font-size: 42px;
          font-weight: 700;
          color: #444444;
          white-space: break-spaces;
          line-height: 54px;
        }

        .text-bottom {
          padding: 30px 0 50px 0;
          font-size: 18px;
          color: #444444;
          white-space: break-spaces;
          line-height: 32px;
        }

        .btn-explore {
          border-radius: 50px;
          width: 200px;
          height: 60px;
          color: #163671;
          border: 2px solid #163671;
          background-color: #ffffff;
          box-shadow: 5px 10px 18px #a8b9d1;

          img {
            padding-left: 10px;
          }
        }
      }
    }
  }

  @include Mobile() {
    .home-header {
      .wrapper-content {
        .text-header {
          font-size: 34px;
          line-height: 45px;
          padding: 0 20px;
        }

        .text-bottom-mb {
          font-size: 16px;
          line-height: 24px;
          padding: 17px 20px 0;
          white-space: break-spaces;
          opacity: 80%;
        }

        .main-mb-img {
          width: 100%;
          object-fit: cover;
          height: 350px;
          align-items: center;
          margin-top: 60px;
        }
      }
    }

    .home-content {
      padding: 50px 20px 100px;

      .content-header {
        flex-direction: column;

        .main-pc06 {
          height: 220px;
        }

        .wrapper-right {
          margin-top: 50px;
          text-align: center;

          .text-header {
            font-size: 28px;
            line-height: 42px;
          }

          .text-bottom {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .content-second {
        flex-direction: column;
        gap: 0;

        .main-pc06 {
          width: 100%;
        }

        .wrapper-right {
          margin-top: 50px;
          text-align: center;

          .text-header {
            font-size: 28px;
            line-height: 42px;
          }

          .text-bottom {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .content-bottom {
        flex-direction: column;

        .main-pc04 {
          height: 350px;
        }

        .wrapper-right {
          margin-top: 50px;
          text-align: center;

          .text-header {
            font-size: 28px;
            line-height: 42px;
          }

          .text-bottom {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  @include Tablet() {
    .home-header {
      .wrapper-content {
        .text-header {
          font-size: 50px;
          padding: 0 40px;
        }

        .text-bottom {
          padding: 0 40px;
        }

        .main-pc-img {
          height: 420px;
        }
      }
    }

    .home-content {
      padding: 50px 40px 100px;

      .content-header {
        .main-pc03 {
          height: 344px;
        }

        .wrapper-right {
          .text-header {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }

      .content-second {
        .main-pc06 {
          height: 300px;
        }

        .wrapper-right {
          .text-header {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }

      .content-bottom {
        .main-pc04 {
          height: 500px;
        }

        .wrapper-right {
          .text-header {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }
    }
  }
}