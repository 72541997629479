@import 'variables';
@import 'mixin';
@import 'global';

.customers-page {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $page-content-width;
    margin: auto;
    padding: 200px 0 180px 0;

    .content {
      flex: 1 0 50%;

      .text-header {
        font-size: 48px;
        white-space: break-spaces;
        line-height: 72px;
        color: #113577;
      }

      .text-middle {
        font-size: 18px;
        white-space: break-spaces;
        line-height: 32px;
        color: #444444;
        padding: 30px 0 50px 0;
      }

      .text-bottom {
        font-size: 18px;
        line-height: 32px;
        color: #113577;
      }
    }

    .img-customers01 {
      width: 100%;
      border-radius: 15px;
    }
  }

  @include Mobile {
    .wrapper {
      flex-direction: column-reverse;
      padding: 100px 20px;

      .img-customers01 {
        width: 100%;
      }

      .content {
        margin-top: 50px;
        text-align: center;

        .text-header {
          font-size: 28px;
          line-height: 42px;
        }

        .text-middle {
          font-size: 16px;
          line-height: 24px;
          padding: 25px 0 40px 0;
        }
      }
    }
  }

  @include Tablet {
    .wrapper {
      padding: 150px 40px;

      .content {
        flex: 1 0 50%;

        .text-header {
          font-size: 30px;
          line-height: 48px;
        }
      }
    }
  }
}