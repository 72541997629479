@mixin Mobile() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin PC() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin Tablet() {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}