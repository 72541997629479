@import 'variables';
@import 'mixin';
@import 'global';

.pricing-page {
  .section-01 {
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: $page-content-width;
      margin: auto;
      padding: 200px 0 150px 0;

      .content {
        flex: 1 0 50%;

        .text-header {
          font-size: 48px;
          white-space: break-spaces;
          line-height: 72px;
          color: #113577;
        }

        .text-bottom {
          font-size: 18px;
          white-space: break-spaces;
          line-height: 32px;
          color: #444444;
          padding: 20px 0 20px 0;
        }

        .btn-pricing {
          font-size: 18px;
          border-radius: 50px;
          width: 200px;
          height: 60px;
          color: #163671;
          background-color: #ffffff;
          border: 2px solid #163671;
          box-shadow: 5px 10px 18px #a8b9d1;
        }
      }

      .img-pricing01 {
        width: 100%;
      }
    }
  }

  .section-02 {
    background-color: #fafbfc;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: $page-content-width;
      margin: auto;
      padding: 150px 0 150px 0;

      .content {
        flex: 1 0 50%;

        .text-header {
          font-size: 48px;
          white-space: break-spaces;
          line-height: 72px;
          color: #113577;
        }

        .text-bottom {
          font-size: 18px;
          white-space: break-spaces;
          line-height: 32px;
          color: #444444;
          padding: 20px 0 50px 0;
        }

        .btn-pricing {
          font-size: 18px;
          border-radius: 50px;
          width: 200px;
          height: 60px;
          color: #163671;
          background-color: #ffffff;
          border: 2px solid #163671;
          box-shadow: 5px 10px 18px #a8b9d1;
        }
      }

      .contact-us-wrapper {
        position: relative;

        .img-pricing01 {
          width: 100%;
        }

        .contact-img-text {
          color: #ffffff;
          position: absolute;
          top: 63%;
          left: 16%;
          width: 65%;
          font-size: 16px;
          white-space: break-spaces;
          line-height: 30px;
        }
      }
    }
  }

  @include Mobile {
    .section-01 {
      .wrapper {
        padding: 120px 20px 150px 20px;
        flex-direction: column;

        .img-pricing01 {
          width: 100%;
        }

        .content {
          margin-top: 50px;
          text-align: center;

          .text-header {
            font-size: 28px;
            line-height: 42px;
          }

          .text-bottom {
            font-size: 16px;
            line-height: 24px;
            padding: 20px 0 40px 0;
          }
        }
      }
    }

    .section-02 {
      .wrapper {
        padding: 150px 20px;
        flex-direction: column;

        .img-pricing01 {
          width: 330px;
        }

        .content {
          margin-top: 50px;
          text-align: center;

          .text-header {
            font-size: 28px;
            line-height: 42px;
          }

          .text-bottom {
            font-size: 16px;
            line-height: 24px;
            padding: 20px 0 40px 0;
          }
        }

        .contact-us-wrapper {
          position: relative;

          .img-pricing01 {
            width: 100%;
          }

          .contact-img-text {
            color: #ffffff;
            position: absolute;
            top: 62%;
            left: 17%;
            width: 70%;
            font-size: 14px;
            white-space: break-spaces;
            line-height: 20px;
          }
        }
      }
    }
  }

  @include Tablet {
    .section-01 {
      .wrapper {
        padding: 150px 40px;

        .content {
          flex: 1 0 50%;

          .text-header {
            font-size: 30px;
            line-height: 48px;
          }

          .text-bottom {
            font-size: 16px;
          }
        }

        .img-pricing01 {
          width: 100%;
        }
      }
    }

    .section-02 {
      .wrapper {
        padding: 150px 40px;

        .content {
          flex: 1 0 50%;

          .text-header {
            font-size: 30px;
            line-height: 48px;
          }

          .text-bottom {
            font-size: 16px;
          }
        }

        .contact-us-wrapper {
          .img-pricing01 {
            width: 100%;
          }

          .contact-img-text {
            font-size: 14px;
            line-height: normal;
          }
        }

      }
    }
  }
}