@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import 'mixin';

@font-face {
  font-family: "GmarketSans";
  src: url("../assets/Fonts/GmarketSans/GmarketSansLight.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GmarketSans";
  src: url("../assets/Fonts/GmarketSans/GmarketSansMedium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "GmarketSans";
  src: url("../assets/Fonts/GmarketSans/GmarketSansBold.otf") format("opentype");
  font-weight: 700;
}

@include Mobile() {
  .hidden-mobile {
    display: none !important;
  }
}

@include PC() {
  .hidden-pc {
    display: none !important;
  }
}

.hover {
  &:hover {
    cursor: pointer;
    opacity: 100%;
  }
}

.hover-opacity {
  &:hover {
    opacity: 100%;
  }
}

:root {
  --bs-font-sans-serif: 'Poppins', sans-serif;

}

.family-ko {
  font-family: 'GmarketSans', sans-serif;
}

.family-en {
  font-family: 'Poppins', sans-serif;
}

.fs-18-16 {
  font-size: 18px;
  line-height: 26px;

  @include Mobile() {
    font-size: 16px;
  }

}

.w-10 {
  width: 10px;

  img {
    width: 100%;
  }
}