@import 'variables';
@import 'mixin';
@import 'global';

.footer {
  background-color: #163671;
  color: #ffffff;
  overflow: hidden;

  .home-register {
    background-color: #f2f6fc;
    max-height: 420px;
    text-align: center;

    .register-header {
      padding: 86px 0 32px 0;
      color: #163671;
      font-size: 20px;
    }

    .text-header {
      color: #222222;
      font-size: 40px;
      padding-bottom: 65px;
    }

    .submit {
      max-width: 800px;
      margin: auto;
      padding-bottom: 116px;
      display: flex;
      gap: 16px;

      .input-submit {
        border-radius: 50px;
        height: 60px;
        border: 1px solid #ffffff;
        padding-left: 30px;
        flex-grow: 1;
      }

      .btn-submit {
        width: 180px;
        height: 60px;
        border-radius: 50px;
        background-color: #163671;
        border: 1px solid #163671;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }

  .grid-container {
    width: $page-content-width;
    margin: auto;
    display: grid;
    grid-template-areas:
      'navbar-footer navbar-footer logo-footer'
      'navbar-footer navbar-footer list-fanpage'
      'copy-right  copy-right copy-right';
    gap: 0 100px;

    // padding: 20px 0;
    .logo-footer {
      grid-area: logo-footer;
      line-height: 40px;
      padding-top: 40px;
      // width: 250px;

      img {
        width: 136px;
      }

      .text-area {
        // width: 370px;
        opacity: 50%;
        font-size: 14px;
        white-space: pre-line;
        line-height: 30px;
      }

      .language {
        font-size: 16px;
      }
    }

    .navbar-footer {
      grid-area: navbar-footer;
      padding-top: 40px;

      .nav {
        display: flex;
        justify-content: space-between;
        font-size: 1.1rem;

        .nav-link {
          color: #ffffff;

          .content-center {
            font-size: 14px;
            line-height: 30px;

            div {
              opacity: 50%;
              transition: 500ms;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .copy-right {
      grid-area: copy-right;
      font-size: 14px;
      color: #6c8ecc;
      border-top: 1px solid;
      padding: 17px 0;
      display: flex;
      align-items: center;
    }

    .list-fanpage {
      grid-area: list-fanpage;
      display: flex;
      gap: 12px;
      justify-content: flex-start;
      position: relative;
      padding: 17px 0;

      .social-item {

        border-radius: 100%;
        background-color: rgba($color: #6C8ECC, $alpha: 0.3);
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba($color: #6C8ECC, $alpha: 1);
        }

        img {
          height: 45%;
        }

      }

      .contact-us-item {

        border-radius: 100%;
        background-color: rgba($color: #6C8ECC, $alpha: 0.3);
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba($color: #6C8ECC, $alpha: 1);
        }

        img {
          height: 45%;
        }

      }

      // img {
      //   cursor: pointer;
      //   opacity: 50%;
      //   transition: 500ms;



      // }
    }

    .left-menu {
      width: 100%;
    }

    .right-menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .right-menu-text {
        margin: 0 10px 0 0px;
        opacity: 0.7;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }

      .social-item {

        border-radius: 100%;
        background-color: rgba($color: #6C8ECC, $alpha: 0.3);
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba($color: #6C8ECC, $alpha: 1);
        }

        img {
          height: 45%;
        }

      }

      .contact-us-item {

        border-radius: 100%;
        background-color: rgba($color: #D6E0E6, $alpha: 1);
        width: 20px;
        height: 20px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba($color: #6C8ECC, $alpha: 1);
        }

        img {
          height: 70%;
        }

      }
    }
  }

  @include Tablet() {
    .home-register {
      padding: 0 20px;
    }

    .grid-container {
      width: $footer-content-width-tablet;
      grid-template-areas:
        'navbar-footer logo-footer'
        'navbar-footer list-fanpage'
        'copy-right copy-right';
      gap: 5px 10px;

      .logo-footer {
        padding-left: 20px;
      }

      .copy-right {
        padding-left: 20px;
      }

      .left-menu {
        width: 100%;
      }

      .right-menu {
        display: none;
      }

      .navbar-footer {
        padding-right: 20px;
      }

      .list-fanpage {
        padding-right: 20px;
      }
    }
  }

  @include Mobile() {
    .home-register {
      padding-left: 20px;
      padding-right: 20px;

      .register-header {
        font-size: 16px;
        padding-top: 60px;
      }

      .text-header {
        font-size: 24px;
        white-space: break-spaces;
      }

      .submit {
        font-size: 13px;
        padding-bottom: 55px;

        .input-submit {
          height: 40px;
          padding-left: 15px;
        }

        .btn-submit {
          height: 40px;
          width: 100px;
        }
      }
    }

    .grid-container {
      grid-template-areas:
        'navbar-footer '
        'list-fanpage'
        'logo-footer'
        'copy-right ';
      gap: 20px 60px;

      .logo-footer {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text-area {
          font-size: 14px;
          opacity: 50%;
        }
      }

      .navbar-footer {
        padding-top: 20px;
      
        .nav {
          display: grid;
          grid-template-columns: 0.5fr 1fr;
          gap: 10px;
          padding: 0 20px;
          font-size: 16px;
      
          .nav-link {
            padding: 0;
      
            .content-center {
              font-size: 14px;
            }
          }
      
          hr {
            margin: 0;
          }
      
          .item {
            // display: flex;
            // justify-content: space-between;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            font-size: 20px;
            align-items: center;
      
            .content-center {
              font-size: 14px;
              opacity: 80%;
              text-align: center;
            }
          }
        }
      }

      .left-menu {
        width: 100%;
        text-align: center;
      }

      .right-menu {
        display: none;
      }

      .copy-right {
        justify-content: center;
      }

      .list-fanpage {
        flex-wrap: wrap;
        gap: 7px;
        padding: 0 15px;
      }
    }
  }
}